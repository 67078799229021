import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnDestroy,
  OnInit,
  Signal,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HlmToasterComponent } from '@spartan-ng/ui-sonner-helm';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { I18N_AVAILABLE_VOCABS } from '../_config';
import { LocaleService } from './i18n/service/locale.service';
import { CustomLoaderService } from './shared/services/custom-loader.service';
import { ThemeService } from './shared/services/theme.service';
import { ConnectionStatus } from './shared/utils/tools/connection-status';

@Component({
  selector: 'body[root-component]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, NgxUiLoaderModule, HlmToasterComponent],
  template: `
    <hlm-toaster [theme]="themeService.mode" />
    <ngx-ui-loader
      [text]="loaderConfig().text || ''"
      [textColor]="loaderConfig().textColor || 'black'"
      [textPosition]="loaderConfig().textPosition || 'center-center'"
      [hasProgressBar]="true"
      overlayColor="transparent"
      [fgsSize]="0" />
    <router-outlet />
  `,
  styles: '',
  host: {
    '[class]': 'computedTheme()',
  },
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly localeService = inject(LocaleService);
  private readonly customLoaderService = inject(CustomLoaderService);
  protected readonly themeService = inject(ThemeService);
  private readonly connectionStatusService = new ConnectionStatus();

  loaderConfig: Signal<NgxUiLoaderConfig> =
    this.customLoaderService.getConfig();
  computedTheme = computed(
    () =>
      'h-full m-0 transition-colors duration-500 bg-gray-100 dark:bg-gray-900 ' +
      this.themeService.theme(),
  );

  ngOnInit(): void {
    // register translations
    this.localeService.loadLocalesTranslations(...I18N_AVAILABLE_VOCABS);
    // init locale
    this.localeService.initLocale();

    this.themeService.init();

    this.connectionStatusService.startListener();
  }

  ngOnDestroy(): void {
    this.connectionStatusService.stopListener();
    this.localeService.destroySubscription();
  }
}
