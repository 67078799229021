import { NgModule } from '@angular/core';
import { HlmToasterComponent } from './lib/hlm-toaster.component';

export * from './lib/hlm-toaster.component';

@NgModule({
	imports: [HlmToasterComponent],
	exports: [HlmToasterComponent],
})
export class HlmToasterModule {}
