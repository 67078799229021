import { Route, Routes } from '@angular/router';
import { AccessGuardCanActivate } from '@modules/auth/services/access.guard';


export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/routes').then((r) => r.SecurityRoutes),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/core/errors/routes').then((r) => r.ErrorsRoutes),
  },
  {
    path: '',
    canActivate: [AccessGuardCanActivate],
    data: { breadcrumb: 'Inicio' },
    loadComponent: () =>
      import('./pages/core/layout/layout.component').then(
        (c) => c.LayoutComponent,
      ),
    loadChildren: (): Route[] => LayoutMainRoutes,
  },
  { path: '', redirectTo: './modules/user/routes', pathMatch: 'full' },
  { path: '**', redirectTo: 'error/404' },
];

const LayoutMainRoutes: Route[] = [
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/user-profile/routes').then((r) => r.UserProfileRoutes),
  },
  {
    path: 'center',
    data: { breadcrumb: 'Centros' },
    loadChildren: () =>
      import('./modules/center/routes').then((r) => r.CenterRoutes),
  },

  {
    path: 'management',
    data: { breadcrumb: { skip: true } },
    loadChildren: () =>
      import('./modules/user/routes').then((r) => r.UserManagementRoutes),
  },
  {
    path: 'specialist',
    data: { breadcrumb: 'Especialistas' },
    loadChildren: () =>
      import('./modules/specialist/routes').then((r) => r.SpecialistRoutes),
  },
  {
    path: 'statistics',
    data: { breadcrumb: 'Estadísticas' },
    loadChildren: () =>
      import('./modules/statistics/routes').then((r) => r.StatisticsRoutes),
  },
  {
    path: 'all-user',
    data: { breadcrumb: 'Usuarios' },
    loadChildren: () =>
      import('./modules/all-user/routes').then((r) => r.AllUserRoutes),
  },
  { path: '', redirectTo: 'management', pathMatch: 'full' },
  { path: '**', redirectTo: 'error/404' },
];
