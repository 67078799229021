import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withViewTransitions,
} from '@angular/router';

import { DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateModule } from '@ngx-translate/core';
import { FlatpickrModule } from 'angularx-flatpickr';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { FLATPICKER_CONFIG, LOADER_UI_CONFIG } from '../_config';
import { routes } from './app.routes';
import { GraphQLModule } from './graphql.module';
import { AuthService } from './modules/auth';
import { appInterceptorFn } from './shared/interceptor/interceptors';
import {
  DatePipeProvider,
  GlobalErrorHandlerProvider,
  LocaleProvider,
} from './shared/provider';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise(async (resolve) => {
      if (AuthService.isExpiredRefreshToken()) {
        resolve(null);
        return;
      }

      const getAuthUserData = await lastValueFrom(
        authService.getAuthenticatedUser(),
      ).catch((r: unknown) => r);

      if (!getAuthUserData) {
        authService.logout({ redirectToCurrentUrl: true });
        resolve(null);
        return;
      }

      resolve(true);
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    provideAnimationsAsync(),
    provideExperimentalZonelessChangeDetection(),
    provideRouter(
      routes,
      withViewTransitions(), //browser experimental feature only Chrome
      withComponentInputBinding(),
    ),
    provideHttpClient(withInterceptors([appInterceptorFn])),
    DatePipe,
    GlobalErrorHandlerProvider,
    LocaleProvider,
    DatePipeProvider,
    provideEnvironmentNgxMask(),
    importProvidersFrom(
      GraphQLModule,
      TranslateModule.forRoot(),
      InlineSVGModule.forRoot(),
      NgxUiLoaderModule.forRoot(LOADER_UI_CONFIG),
      NgxUiLoaderRouterModule.forRoot({}),
      /* NgxUiLoaderHttpModule.forRoot({ showForeground: false }), */
      FlatpickrModule.forRoot(FLATPICKER_CONFIG),
    ),
  ],
};
