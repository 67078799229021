import { LOCALE_ID, Provider } from '@angular/core';
import { LocaleService } from '../../i18n/service/locale.service';

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class LocaleID extends String {
  constructor(private localeService: LocaleService) {
    super();
  }

  override toString(): string {
    return this.localeService.currentLocale;
  }

  override valueOf(): string {
    return this.toString();
  }
}

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleID,
  deps: [LocaleService],
};
