import { Provider } from '@angular/core';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import {
  APP_DEFAULT_DATE_FORMAT_PIPE,
  APP_DEFAULT_DATE_TIMEZONE_PIPE,
} from '../../../_config';

export const DatePipeProvider: Provider = [
  {
    provide: DATE_PIPE_DEFAULT_OPTIONS,
    useValue: {
      dateFormat: APP_DEFAULT_DATE_FORMAT_PIPE,
      timezone: APP_DEFAULT_DATE_TIMEZONE_PIPE,
    },
  },
];
