import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { Observable, of, retry, tap, throwError, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  handleHttpInvalidTokenErrorsFn,
  INVALID_TOKEN_ERRORS,
} from 'src/_config';
import { TokenModel } from 'src/app/modules/auth/models/token.model';
import { AuthService } from '../../modules/auth';
import { CacheInterceptorService } from '../services';

/**
 * Intercepts all HTTP requests and adds an Authorization header with a JWT token if available.
 * It also handles 401 errors with an invalid JWT token by logging out the user and redirecting to the login page.
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const appInterceptorFn: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const injector = inject(Injector);
  const authService = inject(AuthService);

  const authToken = authService.authToken;

  const requestProps: Record<string, any> = {
    withCredentials: true,
  };

  // if url contains graphql then add the auth header
  if (request.url.includes('/api/graphql')) {
    requestProps.headers = request.headers.set(
      'Authorization',
      'Bearer ' + authToken || 'undefined',
    );
  }

  //send the request with config props
  request = request.clone(requestProps);

  //check if the request is to invalidate the token
  if (request.url.includes('/api/token/invalidate')) {
    return next(request).pipe(catchError(() => of()));
  }

  return next(request).pipe(
    retry({
      count: 1,
      delay: (_, retryCount) => timer(retryCount * 1000),
    }),
    catchError((reason) => {
      if (!reason.url.includes('refresh_token')) {
        if (
          401 === reason.error.code &&
          INVALID_TOKEN_ERRORS.includes(reason.error.message)
        ) {
          return authService.refreshToken().pipe(
            switchMap((newToken: TokenModel | undefined) => {
              // Retry the original request with the new JWT token
              request = request.clone({
                headers: request.headers.set(
                  'Authorization',
                  'Bearer ' + newToken?.token,
                ),
                withCredentials: true,
              });

              return next(request);
            }),
            catchError(() => of()),
          );
        }

        return throwError(() => reason);
      }

      handleHttpInvalidTokenErrorsFn(reason.error, injector);
      return of();
    }),
  );
};

/**
 * Caching interceptor function that intercepts HTTP requests and responses.
 * It checks if the requested data is present in the cache, and if so, returns the cached response.
 * Otherwise, it forwards the request to the next handler and caches the response for future use.
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const cachingInterceptorFn: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const cache = inject(CacheInterceptorService);

  const cached = cache.get(request.url);
  const isCacheHit = undefined !== cached;
  if (isCacheHit) {
    return of(cached);
  }

  return next(request).pipe(
    tap((response) => cache.set(request.url, response)),
  );
};
